@keyframes backgroundFX {
  /* 0% {background: rgb(116,116,116);
    background: radial-gradient(circle, rgba(116,116,116,1) 0%, rgba(92,92,92,1) 33%, rgba(66,66,66,1) 100%);}
  100% {
    background: rgb(92,92,92);
    background: radial-gradient(circle, rgba(92,92,92,1) 33%, rgba(116,116,116,1) 100%, rgba(116,116,116,1) 424242%);} */
    0% {background: url('./media/01mobile.jpg');}
    100% {background: url('./media/01mobilea.jpg');}
  
}

html {
  /* background: rgb(116,116,116);
  background: radial-gradient(circle, rgba(116,116,116,1) 0%, rgba(92,92,92,1) 33%, rgba(66,66,66,1) 100%); */
  animation: backgroundFX 5s infinite alternate;
  background-repeat: no-repeat;
  background-size: cover;
}


body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

