 /* MOBILE FIRST */
 /* YELLOW */

 #app-container {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  
  /* background: rgb(116,116,116);
  background: radial-gradient(circle, rgba(116,116,116,1) 0%, rgba(92,92,92,1) 33%, rgba(66,66,66,1) 100%); */
  /* background-image: url('/src/media/01_mobile.jpg'); */
  }

  .title-text{
    font-size: 5vw;
    font-weight: 800;
    margin: 30px;
  }

  .box-tile{
    box-shadow: 0 0 10px rgb(37, 37, 37);
    width: 300px;
    height: 200px;
    margin: 20px;
    padding: 10px;
    background: rgb(80, 80, 80);
  }

  .tile-text{
    font-size: 3vw;
    margin: auto;
  }


/* Small devices (landscape phones, 576px and up) */
 /* RED */

@media (min-width: 576px) {

  #app-container1 {
    background-image: url('/src/media/02_small.jpg');
    }

  .box-tile{
    width: 500px;
    height: 300px;
    margin: 40px;
    padding: 10px;
  }
 
  
}



/* Medium devices (tablets, 768px and up) */
 /* BLUE */

@media (min-width: 768px) {

  #app-container1 {
    background-image: url('/src/media/03_medium.jpg');
    }
  
  .box-tile{
    width: 600px;
    height: 300px;
    margin: 60px;
    padding: 10px;
  }

}



/* Large devices (desktops, 992px and up) */
 /* PURPLE */

@media (min-width: 992px) {
  #app-container1 {
    background-image: url('/src/media/04_large.jpg');
    }

  .box-tile{
    width: 700px;
    height: 350px;
    margin: 60px;
    padding: 10px;
  }

}

/* Big screen devices (desktops, 1200px and up) */
 /* PINK */
@media (min-width: 1200px) {

  #app-container1 {
    background-image: url('/src/media/05_xlarge.jpg');
    }

  .box-tilex{
    width: 1200px;
    height: 600px;
    margin: 20px;
    padding: 10px;
  }
}
